console.log("Carousel file loaded");

class Carousel extends elementorModules.frontend.handlers.Base {
  protected swiperInstance: any = null;

  constructor($element: JQuery, args: any) {
    console.log("Carousel run");
    super($element, args);
    const asyncSwiper = elementorFrontend.utils.swiper;
    const swiperElement = this.elements.$swiperContainer;

    const swiperConfig = {
      slidesPerView: 1,
      spaceBetween: 16,
      loop: true,
      autoplay: {
        delay: 8000,
      },
      grabCursor: true,
      effect: "creative",
      creativeEffect: {
        prev: {
          translate: [0, 0, -400],
        },
        next: {
          translate: ["calc(100% +  56px)", 0, 0],
        },
      },
      pagination: {
        el: this.elements.$pagination[0],
        type: "bullets",
        clickable: true,
      },
      breakpoints: {
        768: { slidesPerView: 1, spaceBetween: 24 },
        1200: { slidesPerView: 1.1, spaceBetween: 24 },
      },
    };
    new asyncSwiper(swiperElement, swiperConfig).then((swiper: any) => {
      this.swiperInstance = swiper;
    });
  }

  getDefaultSettings() {
    return {
      selectors: {
        swiperContainer: ".swiper",
        swiperSlides: ".swiper-slide",
        pagination: ".hco-carousel__pagination",
      },
    };
  }
  getDefaultElements() {
    const selectors = this.getSettings("selectors");
    return {
      $swiperContainer: this.$element.find(selectors.swiperContainer),
      $swiperSlides: this.$element.find(selectors.swiperSlides),
      $pagination: this.$element.find(selectors.pagination),
    };
  }
  bindEvents() {}
}

/**
 * Register JS Handler for the Test Widget
 *
 * When Elementor frontend was initiated, and the widget is ready, register the widet
 * JS handler.
 */
window.addEventListener("elementor/frontend/init", () => {
  elementorFrontend.elementsHandler.attachHandler(
    "gravitas_carousel",
    Carousel
  );
});
